// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetJobsRes,
  IGetJobsByAccountNameRes,
  IGetJobsByLocationRes,
  IGetJobsByAccountNameParams,
  ICreateJobBody,
  ICreateJobRes,
  IGetJobRes,
  IUpdateJobBodyWithParam,
  IUpdateJobRes,
  IDeleteJobRes,
  IGetJobsGroupsForSelectRes,
  TJobGroupsForSelectParams,
  IGetJobsByLocationParams,
  IGetJobDetailsWithParams,
  IAddEmployeeToJobBody,
} from 'types/jobsTypes';
import { TQueryParams } from 'types/appTypes';

export const getJobs = (params: TQueryParams) => {
  return xhr.get<IGetJobsRes, IGetJobsRes>(`${ENDPOINTS.jobs}`, { params });
};

export const getJobsByAccountName = ({ accountName, params }: IGetJobsByAccountNameParams) => {
  return xhr.get<IGetJobsByAccountNameRes, IGetJobsByAccountNameRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.jobs}`,
    { params },
  );
};

export const getJobsByLocation = ({
  accountName,
  locationId,
  params,
}: IGetJobsByLocationParams) => {
  return xhr.get<IGetJobsByLocationRes, IGetJobsByLocationRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.locations}/${locationId}${ENDPOINTS.jobs}`,
    { params },
  );
};

export const createJob = (job: ICreateJobBody) => {
  return xhr.post<ICreateJobRes, ICreateJobRes>(`${ENDPOINTS.jobs}`, job);
};

export const getJob = (id: number) => {
  return xhr.get<IGetJobRes, IGetJobRes>(`${ENDPOINTS.jobs}/${id}`);
};

export const updateJob = ({ id, body }: IUpdateJobBodyWithParam) => {
  return xhr.put<IUpdateJobRes, IUpdateJobRes>(`${ENDPOINTS.jobs}/${id}`, body);
};

export const deleteJob = (id: number) => {
  return xhr.delete<IDeleteJobRes, IDeleteJobRes>(`${ENDPOINTS.jobs}/${id}`);
};

export const getJobGroupsForSelect = (params: TJobGroupsForSelectParams) => {
  return xhr.get<IGetJobsGroupsForSelectRes, IGetJobsGroupsForSelectRes>(
    `${ENDPOINTS.jobsGroups}`,
    { params },
  );
};

export const getJobDetails = ({ id, params }: IGetJobDetailsWithParams) => {
  return xhr.get<IGetJobRes, IGetJobRes>(`${ENDPOINTS.jobs}/${id}`, { params });
};

export const addEmployeesToJob = ({ id, body }: IAddEmployeeToJobBody) => {
  return xhr.patch<IUpdateJobRes, IUpdateJobRes>(`${ENDPOINTS.jobs}/${id}`, body);
};
