//Core
import { useState, useCallback } from 'react';
// Components
import SubMenu from './SubMenu';
import SettingsModal from '../SettingsModal';
import { Avatar, Text, GridRow, GridCol } from 'components/lib';
// Redux
import { STORE_KEYS, useAppSelector } from 'redux/store';
// Helpers
import getFullNameInitials from 'helpers/getFullNameInitials';
import { ROLES } from 'helpers/data/constants';
// Styles
import styles from './authMenu.module.scss';

interface IProps {
  onToggleMenu?: () => void;
}

const AuthMenu = ({ onToggleMenu }: IProps) => {
  const { user } = useAppSelector(STORE_KEYS.USER);

  const [isSettingModalVisible, setIsSettingsModalVisible] = useState(false);

  const handleToggleSettingsModal = useCallback(
    () => setIsSettingsModalVisible(prev => !prev),
    [],
  );

  const userInitials = getFullNameInitials(user);
  let headerAccountInfoText = user?.accountSubscriptionStatus;
  const isBusinessCompany = user?.role === ROLES.business_company;
  const headerTextAccountInfoClassName = !isBusinessCompany ? styles.status : '';

  if (isBusinessCompany) {
    headerAccountInfoText = user?.username;
  }

  return (
    <>
      <GridRow align="middle" justify="start" gutter={4}>
        <GridCol
          xs={{ order: 2, span: 24 }}
          sm={{ order: 2, span: 10 }}
          md={{ order: 2, span: 12 }}
          className={styles.colWithSettings}
        >
          <SubMenu
            onToggleMenu={onToggleMenu}
            onSetIsSettingsModalVisible={handleToggleSettingsModal}
          />
        </GridCol>
        <GridCol
          xs={{ order: 1, span: 24 }}
          sm={{ order: 1, span: 14 }}
          md={{ order: 1, span: 12 }}
        >
          <div className={styles.userInfoWrap}>
            <Avatar className={styles.avatar}>{userInitials}</Avatar>

            <div className={styles.fullNameAndRole}>
              <Text fontSize={'middle'}>
                {user?.firstName} {user?.lastName}
              </Text>
              <Text fontSize={'middle'} className={headerTextAccountInfoClassName}>
                {headerAccountInfoText}
              </Text>
            </div>
          </div>
        </GridCol>
      </GridRow>

      <SettingsModal
        isModalVisible={isSettingModalVisible}
        onCloseModal={handleToggleSettingsModal}
      />
    </>
  );
};

export default AuthMenu;
