// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getCheckpointsAsync,
  updateCheckpointAsync,
  deleteCheckpointAsync,
} from './checkpointsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetCheckpointsRes, IGetCheckpointDetails } from 'types/checkpointTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceCheckpointState {
  checkpoints: IGetCheckpointsRes | null;
  checkpointDetails: IGetCheckpointDetails | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceCheckpointState = {
  checkpoints: null,
  checkpointDetails: null,
  error: null,
  isLoading: false,
};

const checkpointsSlice = createSlice({
  name: 'checkpoints',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    setCheckpointDetails(state, { payload }) {
      state.checkpointDetails = payload || null;
    },
  },
  extraReducers: builder => {
    //* GET CHECKPOINTS ASYNC THUNK
    builder.addCase(getCheckpointsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getCheckpointsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.checkpoints = payload;
    });
    builder.addCase(getCheckpointsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE CHECKPOINT (one) ASYNC THUNK
    builder.addCase(updateCheckpointAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateCheckpointAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateCheckpointAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE CHECKPOINT ASYNC THUNK
    builder.addCase(deleteCheckpointAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteCheckpointAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteCheckpointAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export const { setCheckpointDetails } = checkpointsSlice.actions;

export default checkpointsSlice;
