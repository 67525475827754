// Packages
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Redux
import {
  signInAsync,
  signUpAsync,
  signOutAsync,
  forgotPasswordAsync,
  recoverPasswordAsync,
  forgotWebAddressAsync,
  checkAccountAsync,
  preSignUpAsync,
  signInWithTwoFactorCodeAsync,
} from './authThunk';
// Modules
import webStorage from 'modules/storage';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { ISignInResData, ISignResWhenNeedTwoFactorCode } from 'types/authTypes';
import { TSliceReducer, TActionType } from 'redux/store';

function instanceOfISignInResData(
  object: ISignInResData | ISignResWhenNeedTwoFactorCode,
): object is ISignInResData {
  return 'userId' in object;
}

type TAction<T extends TActionType> =
  | typeof signInAsync[T]
  | typeof signUpAsync[T]
  | typeof signOutAsync[T]
  | typeof forgotPasswordAsync[T]
  | typeof recoverPasswordAsync[T]
  | typeof forgotWebAddressAsync[T]
  | typeof checkAccountAsync[T]
  | typeof signInWithTwoFactorCodeAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
  state.error = null;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  accessToken: ISignInResData['tokens']['accessToken'] | null;
  refreshToken: ISignInResData['tokens']['refreshToken'] | null;
  userId: ISignInResData['userId'] | null;
  accountName: string;
  companyName: string | null;
  logoImage: string | null;
  hasScheduleAccess: boolean;
  hasGpsAccess: boolean;
  error: IErrorRes | null;
  isLoading: boolean;
  isLoggedIn: boolean;
}

const initialState: IState = {
  accessToken: webStorage.getData()?.accessToken || null,
  refreshToken: webStorage.getData()?.refreshToken || null,
  userId: webStorage.getData()?.userId || null,
  accountName: webStorage.getData()?.accountName || '',
  companyName: null,
  logoImage: null,
  hasScheduleAccess: false,
  hasGpsAccess: false,
  error: null,
  isLoading: false,
  isLoggedIn: !!webStorage.getData()?.accessToken,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeAccountName: (state, { payload }: PayloadAction<string>) => {
      state.accountName = payload;
    },
    changeLogoImage: (state, { payload }: PayloadAction<string | null>) => {
      state.logoImage = payload;
    },
    clearErrors: state => {
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: builder => {
    //* SIGN IN ASYNC THUNK
    builder.addCase(signInAsync.pending, handleLoadingReducer);
    builder.addCase(signInAsync.fulfilled, (state, { payload }) => {
      if (!instanceOfISignInResData(payload)) {
        state.isLoading = false;
      }

      if (instanceOfISignInResData(payload)) {
        state.accessToken = payload.tokens.accessToken;
        state.refreshToken = payload.tokens.refreshToken;
        state.userId = payload.userId;
        state.hasGpsAccess = payload.hasGpsAccess;
        state.hasScheduleAccess = payload.hasScheduleAccess;
        state.isLoading = false;
        state.isLoggedIn = true;
        state.error = null;
      }
    });
    builder.addCase(signInAsync.rejected, handleErrorReducer);

    //* SIGN IN WITH TWO FACTOR ASYNC THUNK
    builder.addCase(signInWithTwoFactorCodeAsync.pending, handleLoadingReducer);
    builder.addCase(signInWithTwoFactorCodeAsync.fulfilled, (state, { payload }) => {
      state.accessToken = payload.tokens.accessToken;
      state.refreshToken = payload.tokens.refreshToken;
      state.userId = payload.userId;
      state.hasGpsAccess = payload.hasGpsAccess;
      state.hasScheduleAccess = payload.hasScheduleAccess;
      state.isLoading = false;
      state.isLoggedIn = true;
      state.error = null;
    });
    builder.addCase(signInWithTwoFactorCodeAsync.rejected, handleErrorReducer);

    //* PRE-SIGN UP ASYNC THUNK
    builder.addCase(preSignUpAsync.pending, handleLoadingReducer);
    builder.addCase(preSignUpAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(preSignUpAsync.rejected, handleErrorReducer);

    //* SIGN UP ASYNC THUNK
    builder.addCase(signUpAsync.pending, handleLoadingReducer);
    builder.addCase(signUpAsync.fulfilled, (state, { payload }) => {
      state.accessToken = payload.tokens.accessToken;
      state.refreshToken = payload.tokens.refreshToken;
      state.accountName = payload.accountName;
      state.hasGpsAccess = payload.hasGpsAccess;
      state.hasScheduleAccess = payload.hasScheduleAccess;
      state.userId = payload.userId;
      state.isLoading = false;
      state.isLoggedIn = true;
      state.error = null;
    });
    builder.addCase(signUpAsync.rejected, handleErrorReducer);

    //* SIGN OUT ASYNC THUNK
    builder.addCase(signOutAsync.pending, handleLoadingReducer);
    builder.addCase(signOutAsync.fulfilled, state => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.accountName = '';
      state.userId = null;
      state.error = null;
      state.logoImage = '';
      state.hasScheduleAccess = false;
      state.hasGpsAccess = false;
    });

    //* FORGOT WEB ADDRESS THUNK
    builder.addCase(forgotWebAddressAsync.pending, handleLoadingReducer);
    builder.addCase(forgotWebAddressAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(forgotWebAddressAsync.rejected, handleErrorReducer);

    //* FORGOT PASSWORD ASYNC THUNK
    builder.addCase(forgotPasswordAsync.pending, handleLoadingReducer);
    builder.addCase(forgotPasswordAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(forgotPasswordAsync.rejected, handleErrorReducer);

    //* RECOVER PASSWORD ASYNC THUNK
    builder.addCase(recoverPasswordAsync.pending, handleLoadingReducer);
    builder.addCase(recoverPasswordAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(recoverPasswordAsync.rejected, handleErrorReducer);

    //* CHECK ACCOUNT ASYNC THUNK
    builder.addCase(checkAccountAsync.pending, handleLoadingReducer);
    builder.addCase(checkAccountAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.accountName = payload?.accountName;
      state.logoImage = payload?.logoImage;
      state.companyName = payload?.name;
      state.error = null;
    });
    builder.addCase(checkAccountAsync.rejected, handleErrorReducer);
  },
});

export default authSlice;

export const { changeAccountName, changeLogoImage, clearErrors } = authSlice.actions;
