// Core
import { ChangeEvent, CSSProperties, KeyboardEventHandler } from 'react';
// Packages
import { Input as AntInput } from 'antd';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
// Styles
import styles from './textArea.module.scss';

interface ITextareaProps {
  allowClear?: boolean;
  autoComplete?: 'on' | 'off';
  autoSize?: boolean | AutoSizeType;
  bordered?: boolean;
  readOnly?: boolean;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  name?: string;
  showCount?: boolean;
  size?: 'small' | 'middle' | 'large';
  style?: CSSProperties;
  value?: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onPressEnter?: KeyboardEventHandler<HTMLTextAreaElement>;
  onResize?: (size: { width: number; height: number }) => void;
}

/**
 * A basic widget for getting the user input is a text field.
 * Keyboard and mouse can be used for providing or changing data.
 *
 * @description The rest of the props of Input.TextArea are the same as the original textarea.
 *
 * @param allowClear - If allow to remove input content with clear icon
 * @param autoComplete - This attribute indicates whether the value of the control can be automatically completed by the browser
 * @param autoSize - Height autosize feature, can be set to true | false or an object { minRows: 2, maxRows: 6 }
 * @param readOnly - ReadOnly textArea
 * @param bordered - Whether has border style
 * @param className - Component className
 * @param defaultValue - The initial input content
 * @param disabled - 	Whether the input is disabled
 * @param maxLength - The max length
 * @param minLength - The min length
 * @param name - The name of the control.
 * @param showCount - Whether show text count
 * @param size - The size of the input box. Note: in the context of a form, the "large" size is used
 * @param style - To customize the styles
 * @param value - The input content value
 * @param placeholder - Specifies a short hint that describes the expected value of an input field
 * @param onChange - Callback when user input
 * @param onPressEnter - The callback function that is triggered when Enter key is pressed
 * @param onResize - The callback function that is triggered when resize
 */

const Textarea = ({
  allowClear = false,
  autoComplete = 'off',
  autoSize = false,
  bordered = true,
  readOnly = false,
  className,
  defaultValue,
  disabled = false,
  maxLength = 5000,
  minLength,
  name,
  showCount = false,
  size = 'middle',
  style,
  value,
  placeholder = '',
  onChange,
  onPressEnter,
  onResize,
}: ITextareaProps) => (
  <AntInput.TextArea
    allowClear={allowClear}
    autoComplete={autoComplete}
    autoSize={autoSize}
    bordered={bordered}
    className={`${styles.textAreaInput} ${className}`}
    defaultValue={defaultValue}
    disabled={disabled}
    maxLength={maxLength}
    minLength={minLength}
    name={name}
    showCount={showCount}
    size={size}
    readOnly={readOnly}
    style={style}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    onPressEnter={onPressEnter}
    onResize={onResize}
  />
);

export default Textarea;
