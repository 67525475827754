// Core
import { useEffect, useState } from 'react';
// Components
import { Loader } from 'components';
// Modules
import webStorage from 'modules/storage';
// Configs
import { adminUrl } from 'configs/apiConfig';

interface TMessageData {
  userId: number;
  accountName: string;
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
}

const SignInAsAdmin = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const listener = ({ data, origin }: MessageEvent<TMessageData>) => {
      if (origin !== adminUrl) {
        return;
      }

      if (data && data.tokens) {
        const { tokens, ...restData } = data;

        webStorage.setData({
          ...tokens,
          ...restData,
          scheduleJobsIdsFilter: null,
          scheduleEmployeesIdsFilter: null,
          scheduleTypeFilter: null,
        });

        window.location.replace(`/${restData.accountName}/schedule`);
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);

      setIsLoading(false);
    };
  }, []);

  return <Loader isLoading={isLoading} />;
};

export default SignInAsAdmin;
