// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { TQueryParams } from 'types/appTypes';
import { IGetScansRes } from 'types/scans';

export const getScans = (params: TQueryParams) => {
  return xhr.get<IGetScansRes, IGetScansRes>(`${ENDPOINTS.scans}`, { params });
};
