// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { CheckpointsAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetCheckpointsRes,
  IUpdateCheckpointBodyWithParam,
  IUpdateCheckpointRes,
  IDeleteCheckpointRes,
} from 'types/checkpointTypes';
import { TQueryParams } from 'types/appTypes';
import { IErrorRes } from 'types/appTypes';

const getCheckpointsAsync = createAsyncThunk<
  IGetCheckpointsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('checkpoints/getCheckpoints', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await CheckpointsAPI.getCheckpoints(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateCheckpointAsync = createAsyncThunk<
  IUpdateCheckpointRes,
  IUpdateCheckpointBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'checkpoints/updateCheckpoint',
  async (cred: IUpdateCheckpointBodyWithParam, { rejectWithValue }) => {
    try {
      return await CheckpointsAPI.updateCheckpoints(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteCheckpointAsync = createAsyncThunk<
  IDeleteCheckpointRes,
  number,
  { rejectValue: IErrorRes }
>('checkpoints/deleteCheckpoint', async (id: number, { rejectWithValue }) => {
  try {
    return await CheckpointsAPI.deleteCheckpoint(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});
export { getCheckpointsAsync, updateCheckpointAsync, deleteCheckpointAsync };
