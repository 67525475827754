// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { TQueryParams } from 'types/appTypes';
import {
  IGetCheckpointsRes,
  IUpdateCheckpointBodyWithParam,
  IUpdateCheckpointRes,
  IDeleteCheckpointRes,
} from 'types/checkpointTypes';

export const getCheckpoints = (params: TQueryParams) => {
  return xhr.get<IGetCheckpointsRes, IGetCheckpointsRes>(`${ENDPOINTS.checkpoints}`, {
    params,
  });
};

export const updateCheckpoints = ({ id, body }: IUpdateCheckpointBodyWithParam) => {
  return xhr.patch<IUpdateCheckpointRes, IUpdateCheckpointRes>(
    `${ENDPOINTS.checkpoints}/${id}`,
    body,
  );
};

export const deleteCheckpoint = (id: number) => {
  return xhr.delete<IDeleteCheckpointRes, IDeleteCheckpointRes>(
    `${ENDPOINTS.checkpoints}/${id}`,
  );
};
