// Packages
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
// API
import { AuthAPI, CompaniesAPI, UserAPI } from 'api/endpoints';
// Modules
import webStorage from 'modules/storage';
import sessionStorageApp from 'modules/storage/sessionStorage';
import xhr from 'modules/xhr';
// Interfaces and types
import {
  ISignInBody,
  ISignInRes,
  ISignUpBody,
  ISignUpRes,
  IForgotAccountNameBody,
  IForgotAccountNameRes,
  IForgotPassBody,
  IForgotPassRes,
  IRecoverPassBody,
  IRecoverPassRes,
  ISignOutBody,
  ISignInResData,
  ISignResWhenNeedTwoFactorCode,
  ISignInWithCodeBody,
} from 'types/authTypes';
import { IErrorRes } from 'types/appTypes';
import { ShiftTypeEnum } from 'types/scheduleTypes';
import { ICompanyAccountNameRes } from 'types/companyTypes';

const checkAccountAsync = createAsyncThunk<
  ICompanyAccountNameRes,
  string,
  { rejectValue: IErrorRes }
>('auth/checkAccount', async (accountName: string, { rejectWithValue }) => {
  try {
    const data = await CompaniesAPI.checkCompanyAccountStatus(accountName);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const signInAsync = createAsyncThunk<ISignInRes, ISignInBody, { rejectValue: IErrorRes }>(
  'auth/signIn',
  async (cred: ISignInBody, { rejectWithValue }) => {
    try {
      const data = await AuthAPI.signIn(cred);

      if ((data as ISignResWhenNeedTwoFactorCode)?.sessionToken) {
        sessionStorageApp.setData({
          accessToken: (data as ISignResWhenNeedTwoFactorCode)?.sessionToken,
        });
      }

      if ((data as ISignInResData).userId) {
        const {
          tokens,
          userId,
          hasGpsAccess,
          hasScheduleAccess,
          hasCheckpointsAccess,
          hasSubscriptionAccess,
        } = data as ISignInResData;

        webStorage.setData({
          ...tokens,
          userId,
          accountName: cred.accountName,
          scheduleJobsIdsFilter: null,
          scheduleEmployeesIdsFilter: null,
          scheduleTypeFilter: null,
          hasGpsAccess,
          hasScheduleAccess,
          hasCheckpointsAccess,
          hasSubscriptionAccess,
        });
      }
      return data;
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const signInWithTwoFactorCodeAsync = createAsyncThunk<
  ISignInResData,
  ISignInWithCodeBody,
  { rejectValue: IErrorRes }
>('auth/signInWithTwoFactorCode', async (cred: ISignInWithCodeBody, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.signInWithTwoFactorCode(cred);
    const {
      tokens,
      userId,
      hasGpsAccess,
      hasScheduleAccess,
      hasCheckpointsAccess,
      hasSubscriptionAccess,
    } = data as ISignInResData;

    webStorage.setData({
      ...tokens,
      userId,
      scheduleJobsIdsFilter: null,
      scheduleEmployeesIdsFilter: null,
      scheduleTypeFilter: null,
      hasGpsAccess,
      hasScheduleAccess,
      hasCheckpointsAccess,
      hasSubscriptionAccess,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const preSignUpAsync = createAsyncThunk<
  ISignUpRes,
  Partial<ISignUpBody>,
  { rejectValue: IErrorRes }
>('auth/preSignUp', async (cred: Partial<ISignUpBody>, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.signUp(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const signUpAsync = createAsyncThunk<
  ISignUpRes,
  Partial<ISignUpBody>,
  { rejectValue: IErrorRes }
>('auth/signUp', async (cred: Partial<ISignUpBody>, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.signUp(cred);

    const { tokens, role, ...restData } = data;

    webStorage.setData({
      ...tokens,
      ...restData,
      scheduleJobsIdsFilter: null,
      scheduleEmployeesIdsFilter: null,
      scheduleTypeFilter: null,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const signOutAsync = createAsyncThunk<void, ISignOutBody, { rejectValue: IErrorRes }>(
  'auth/signOut',
  async (refreshToken: ISignOutBody, { rejectWithValue }) => {
    const { scheduleJobsIdsFilter, scheduleEmployeesIdsFilter, scheduleTypeFilter } =
      webStorage.getData();

    const scheduleFilters = {
      scheduleTypeFilter: scheduleTypeFilter || ShiftTypeEnum.JOBS,
      scheduleEmployeeGroupsFilter: scheduleEmployeesIdsFilter || [],
      scheduleJobGroupsFilter: scheduleJobsIdsFilter || [],
    };

    const signOut = () => {
      delete xhr.defaults.headers.common.Authorization;
      webStorage.unsetData();
      webStorage.unsetData('timeGoUse12Hours');
      sessionStorage.clear();
    };

    try {
      await UserAPI.updateInterfaceSettings(scheduleFilters);
      await AuthAPI.signOut(refreshToken);
      await signOut();
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const clearReducer = createAction('auth/clearReducer');

const forgotWebAddressAsync = createAsyncThunk<
  IForgotAccountNameRes,
  IForgotAccountNameBody,
  { rejectValue: IErrorRes }
>('auth/forgotWeb', async (cred: IForgotAccountNameBody, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.forgotWebAddress(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const forgotPasswordAsync = createAsyncThunk<
  IForgotPassRes,
  IForgotPassBody,
  { rejectValue: IErrorRes }
>('auth/forgotPassword', async (cred: IForgotPassBody, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.forgotPassword(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const recoverPasswordAsync = createAsyncThunk<
  IRecoverPassRes,
  IRecoverPassBody,
  { rejectValue: IErrorRes }
>('auth/recoverPassword', async (cred: IRecoverPassBody, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.recoverPassword(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  preSignUpAsync,
  signInAsync,
  signInWithTwoFactorCodeAsync,
  signUpAsync,
  signOutAsync,
  clearReducer,
  forgotWebAddressAsync,
  forgotPasswordAsync,
  recoverPasswordAsync,
  checkAccountAsync,
};
