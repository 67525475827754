// Redux
import { STORE_KEYS, useAppSelector } from 'redux/store';
// Helpers
import { PERMISSION_KEYS } from 'helpers/data/constants';
// Interfaces and types
import { TRoute, TSubRoute } from 'router/routes';

export interface IUserPagePermissions {
  read: boolean;
  edit: boolean;
  delete: boolean;
  create: boolean;
}

const permissionActions = {
  [PERMISSION_KEYS.IMPLEMENTATION_PLANS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.ACTIVITY]: ['read'],
  [PERMISSION_KEYS.GPS]: ['read'],
  [PERMISSION_KEYS.MAP_VIEW]: ['read'],
  [PERMISSION_KEYS.CALENDAR]: ['read'],
  [PERMISSION_KEYS.SCHEDULE]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.COMPANIES]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.CONTACTS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.LOCATIONS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.LOCATIONS_DOCS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.JOBS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.JOB_RATES]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.JOB_GROUPS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.JOB_LOGS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.JOB_EXPENSES]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.EMPLOYEES]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.EMPLOYEE_GROUPS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.PERFORMANCE_LOG]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.LEAVE_REQUESTS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.EMPLOYEE_RATES]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.TRAININGS_AND_LICENSES]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.FORMS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.FORM_VIEW]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.FORM_RESULTS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.CHECKPOINTS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.TODOS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.REPORTS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.SETTINGS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.PAYMENTS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.PREFERENCES]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.PAYROLL]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.EQUIPMENT]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.INVOICES]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.HELP]: ['read'],
  [PERMISSION_KEYS.FEEDBACK]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.INTEGRATIONS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.ACTION_LOGS]: ['read', 'edit'],
  [PERMISSION_KEYS.LOCATION_RATING]: ['read', 'create', 'delete'],
  [PERMISSION_KEYS.LOCATION_RATING_MAIN_COMPANY]: ['read', 'edit', 'create', 'delete'],
};

export type TPermissions = typeof permissionActions;

export type TPermissionKey = keyof typeof permissionActions;

const useUserPermissions = (neededPermissions: TPermissionKey) => {
  const { user } = useAppSelector(STORE_KEYS.USER);

  const neededPermissionActions = permissionActions[neededPermissions];
  const userPermissionActions = user?.platformPermissions[neededPermissions];

  const allowedPermissions = userPermissionActions?.filter(permission =>
    neededPermissionActions.includes(permission),
  );

  return allowedPermissions?.length === neededPermissionActions?.length;
};

type RouteType = TRoute | TSubRoute;

const useRoutePermissions = <T extends RouteType[]>(routes: T): T => {
  const { user } = useAppSelector(STORE_KEYS.USER);

  if (!user) {
    return routes.filter(
      ({ isAvailable, private: privateRoute }: RouteType) => isAvailable && !privateRoute,
    ) as T;
  }

  const isPermissionsMatch = (route: RouteType, permissions: TPermissions) => {
    return permissions[route.permissionKey as TPermissionKey].includes('read');
  };

  const allowedRoutes = routes.filter(route => {
    return route.isAvailable || isPermissionsMatch(route, user.platformPermissions);
  });

  return allowedRoutes as T;
};

const useUserPermissionsDetails = (neededPermissions: TPermissionKey) => {
  const { user } = useAppSelector(STORE_KEYS.USER);
  const userPermissionActions = user?.platformPermissions[neededPermissions] || [];

  let userPagePermission: IUserPagePermissions = {
    read: false,
    edit: false,
    delete: false,
    create: false,
  };

  const pagePermissions = (userPermissionActions as string[])?.reduce(
    (acc: IUserPagePermissions, curr: string) => {
      return { ...acc, [curr]: true };
    },
    userPagePermission,
  );

  return pagePermissions;
};

export { useUserPermissions, useRoutePermissions, useUserPermissionsDetails };
