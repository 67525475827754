// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetEmployeesRes,
  ICreateEmployeeRes,
  ICreateEmployeeBody,
  IUpdateEmployeeRes,
  IUpdateEmployeeBody,
  IEmployeeRes,
  IDeleteEmployeeRes,
  IGetEmployeeAvailableGroupsRes,
  IImportEmployeesCSVBody,
  IImportEmployeesCSVRes,
  IParseEmployeesCSVRes,
  IGetAvailableEmployeesRes,
  TAvailableEmployeesParams,
} from 'types/employeeTypes';
import { TQueryParams } from 'types/appTypes';

export const getEmployees = (params: TQueryParams) => {
  return xhr.get<IGetEmployeesRes, IGetEmployeesRes>(`${ENDPOINTS.employees}`, {
    params,
  });
};

export const createEmployee = (body: ICreateEmployeeBody) => {
  return xhr.post<ICreateEmployeeRes, ICreateEmployeeRes>(`${ENDPOINTS.employees}`, body);
};

export const getEmployeeAvailableGroups = () => {
  return xhr.get<IGetEmployeeAvailableGroupsRes, IGetEmployeeAvailableGroupsRes>(
    `${ENDPOINTS.groups}?columns=id&columns=name`,
  );
};

export const getAvailableEmployees = (params: TAvailableEmployeesParams) => {
  return xhr.get<IGetAvailableEmployeesRes, IGetAvailableEmployeesRes>(
    `${ENDPOINTS.employees}`,
    { params },
  );
};

export const getEmployeeDetails = (userId: number) => {
  return xhr.get<IEmployeeRes, IEmployeeRes>(`${ENDPOINTS.employees}/${userId}`);
};

export const updateEmployee = ({ userId, body }: IUpdateEmployeeBody) => {
  return xhr.put<IUpdateEmployeeRes, IUpdateEmployeeRes>(
    `${ENDPOINTS.employees}/${userId}`,
    body,
  );
};

export const deleteEmployee = (userId: number) => {
  return xhr.delete<IDeleteEmployeeRes, IDeleteEmployeeRes>(
    `${ENDPOINTS.employees}/${userId}`,
  );
};

export const parseEmployeesCSV = (file: FormData) => {
  return xhr.post<IParseEmployeesCSVRes, IParseEmployeesCSVRes>(
    `${ENDPOINTS.parseEmployees}`,
    file,
  );
};

export const importEmployeesCSV = (body: IImportEmployeesCSVBody) => {
  return xhr.post<IImportEmployeesCSVRes, IImportEmployeesCSVRes>(
    `${ENDPOINTS.importEmployees}`,
    body,
  );
};

export const exportEmployeesCSV = (params: TQueryParams) => {
  return xhr.get<Blob, Blob>(`${ENDPOINTS.exportEmployees}`, { params });
};
