// Packages
import { configureStore, combineReducers, CaseReducer } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
// Redux
import authSlice from './auth/authReducer';
import userSlice from './user/userReducer';
import groupSlice from './group/groupReducer';
import rateSlice from './rate/rateReducer';
import docSlice from './doc/docReducer';
import leaveSlice from './leave/leaveReducer';
import perfLogsSlice from './perfLogs/perfLogsReducer';
import employeeSlice from './employee/employeeReducer';
import companiesSlice from './companies/companiesReducer';
import contactsSlice from './contacts/contactsReducer';
import locationsSlice from './locations/locationsReducer';
import locationsDocsSlice from './locationsDocs/locationsDocsReducer';
import jobsSlice from './jobs/jobsReducer';
import jobRatesSlice from './jobRates/jobRatesReducer';
import jobLogsSlice from './jobLogs/jobLogsReducer';
import jobGroupsSlice from './jobGroups/jobGroupsReducer';
import jobExpensesSlice from './jobExpenses/jobExpensesReducer';
import todoSlice from './todo/todoReducer';
import scheduleSlice from './schedule/scheduleReducer';
import checkpointsSlice from './checkpoints/checkpointsReducer';
import scansSlice from './scans/scansReducer';
import calendarSlice from './calendar/calendarReducer';
import formsSlice from './form/formReducer';
import paymentsSlice from './payments/paymentsReducer';
import payrollPeriodsSlice from './payrollPeriods/payrollPeriodsReducer';
import holidaysSlice from './holidays/HolidayReducer';
import equipmentSlice from './equipment/equipmentReducer';
import equipmentLogsSlice from './equipmentLogs/equipmentLogsReducer';
import equipmentExpensesSlice from './equipmentExpenses/equipmentExpensesReducer';
import invoiceSettingsSlice from './invoiceSettings/invoiceSettingsReducer';
import recurringInvoiceSlice from './recurringInvoices/recurringInvoiceReducer';
import invoiceSlice from './invoices/invoicesReducer';
import TimegoHoursSlice from './integration/integrationReducer';
// import TimegoHoursDifferenceSlice from './integration/integrationReducer';
// import synclogsSlice from './integration/integrationReducer';
import unInvoicedHoursSlice from './unInvoicedHours/unInvoicedHoursReducer';
import actionLogsSlice from './actionLogs/ActionLogsReducer';
import navSlice from './nav/navReducer';
import implementationPlansSlice from './ImplementationPlans/implementationPlansReducer';
import invoiceAgingSlice from './invoiceAging/invoiceAgingReducer';
import locationsRatingsSlice from './locationsRating/locationsRatingsReducer';
import timeOutsideGeofenceReportSlice from './outsideGeofenceReport/outsideGeofenceReportReducer';

const rootReducer = combineReducers({
  // AUTH REDUCER
  [authSlice.name]: authSlice.reducer,
  // USER REDUCER
  [userSlice.name]: userSlice.reducer,
  // EMPLOYEE REDUCER
  [employeeSlice.name]: employeeSlice.reducer,
  [groupSlice.name]: groupSlice.reducer,
  [rateSlice.name]: rateSlice.reducer,
  [leaveSlice.name]: leaveSlice.reducer,
  [perfLogsSlice.name]: perfLogsSlice.reducer,
  [docSlice.name]: docSlice.reducer,
  // COMPANIES REDUCER
  [companiesSlice.name]: companiesSlice.reducer,
  [contactsSlice.name]: contactsSlice.reducer,
  [locationsSlice.name]: locationsSlice.reducer,
  [locationsDocsSlice.name]: locationsDocsSlice.reducer,
  // JOBS REDUCER
  [jobsSlice.name]: jobsSlice.reducer,
  [jobRatesSlice.name]: jobRatesSlice.reducer,
  [jobGroupsSlice.name]: jobGroupsSlice.reducer,
  [jobLogsSlice.name]: jobLogsSlice.reducer,
  [jobExpensesSlice.name]: jobExpensesSlice.reducer,
  // TO-DO REDUCER
  [todoSlice.name]: todoSlice.reducer,
  // SCHEDULE REDUCER
  [scheduleSlice.name]: scheduleSlice.reducer,
  // CHECKPOINTS REDUCER
  [checkpointsSlice.name]: checkpointsSlice.reducer,
  [scansSlice.name]: scansSlice.reducer,
  // CALENDAR REDUCER
  [calendarSlice.name]: calendarSlice.reducer,
  // FORMS REDUCER
  [formsSlice.name]: formsSlice.reducer,
  // PAYMENTS REDUCER
  [paymentsSlice.name]: paymentsSlice.reducer,
  // PAYROLL PERIODS REDUCER
  [payrollPeriodsSlice.name]: payrollPeriodsSlice.reducer,
  // HOLIDAYS REDUCER
  [holidaysSlice.name]: holidaysSlice.reducer,
  // EQUIPMENT REDUCER
  [equipmentSlice.name]: equipmentSlice.reducer,
  [equipmentLogsSlice.name]: equipmentLogsSlice.reducer,
  [equipmentExpensesSlice.name]: equipmentExpensesSlice.reducer,
  // INVOICES REDUCER
  [invoiceSettingsSlice.name]: invoiceSettingsSlice.reducer,
  [recurringInvoiceSlice.name]: recurringInvoiceSlice.reducer,
  [invoiceSlice.name]: invoiceSlice.reducer,
  [unInvoicedHoursSlice.name]: unInvoicedHoursSlice.reducer,
  [invoiceAgingSlice.name]: invoiceAgingSlice.reducer,
  // TIMEGO HOURS REDUCER
  [TimegoHoursSlice.name]: TimegoHoursSlice.reducer,
  // TIMEGO HOURS DIFFERENCE REDUCER
  // [TimegoHoursDifferenceSlice.name]: TimegoHoursSlice.reducer,
  // // SYNC LOGS REDUCER
  // [synclogsSlice.name]: synclogsSlice.reducer,
  // ACTION LOGS REDUCER
  [actionLogsSlice.name]: actionLogsSlice.reducer,
  // NAV LOGS REDUCER
  [navSlice.name]: navSlice.reducer,
  // IMPLEMENTATION PLAN REDUCER
  [implementationPlansSlice.name]: implementationPlansSlice.reducer,
  // REPORTS REDUCER
  [timeOutsideGeofenceReportSlice.name]: timeOutsideGeofenceReportSlice.reducer,
  [locationsRatingsSlice.name]: locationsRatingsSlice.reducer,
});

//STORE.TS

// STORE INIT
export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
});

// ROOT STATE
type RootReducerType = typeof rootReducer;
export type RootState = ReturnType<RootReducerType>;

// USE DISPATCH
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// USE SELECTOR
type TStoreKeys = keyof RootState;
const getConnectedStore = <K extends TStoreKeys>(key: K) => {
  return () => useSelector((state: RootState) => state[key]);
};
export const useAppSelector = <K extends TStoreKeys>(key: K) => getConnectedStore(key)();

export const STORE_KEYS = {
  AUTH: authSlice.name,
  USER: userSlice.name,
  EMPLOYEE: employeeSlice.name,
  GROUP: groupSlice.name,
  RATE: rateSlice.name,
  LEAVE: leaveSlice.name,
  PERF_LOGS: perfLogsSlice.name,
  DOC: docSlice.name,
  COMPANIES: companiesSlice.name,
  CONTACTS: contactsSlice.name,
  LOCATIONS: locationsSlice.name,
  LOCATIONS_DOCS: locationsDocsSlice.name,
  JOBS: jobsSlice.name,
  JOB_RATES: jobRatesSlice.name,
  JOB_GROUPS: jobGroupsSlice.name,
  JOB_LOGS: jobLogsSlice.name,
  JOB_EXPENSES: jobExpensesSlice.name,
  TODO: todoSlice.name,
  SCHEDULE: scheduleSlice.name,
  CHECKPOINTS: checkpointsSlice.name,
  SCANS: scansSlice.name,
  CALENDAR: calendarSlice.name,
  FORMS: formsSlice.name,
  PAYMENTS: paymentsSlice.name,
  PAYROLLS: payrollPeriodsSlice.name,
  HOLIDAYS: holidaysSlice.name,
  EQUIPMENT: equipmentSlice.name,
  EQUIPMENT_LOGS: equipmentLogsSlice.name,
  EQUIPMENT_EXPENSES: equipmentExpensesSlice.name,
  INVOICE_SETTINGS: invoiceSettingsSlice.name,
  RECURRING_INVOICE: recurringInvoiceSlice.name,
  INVOICE: invoiceSlice.name,
  UN_INVOICED_HOURS: unInvoicedHoursSlice.name,
  TIMGOHOURS: TimegoHoursSlice.name,
  TIMEGOHOURSDIFFRENCE: TimegoHoursSlice.name,
  SYNCLOGS: TimegoHoursSlice.name,
  ACTION_LOGS: actionLogsSlice.name,
  NAV: navSlice.name,
  IMPLEMENTATION_PLANS: implementationPlansSlice.name,
  INVOICE_AGING: invoiceAgingSlice.name,
  TIME_OUTSIDE_GEOFENCE: timeOutsideGeofenceReportSlice.name,
  LOCATIONS_RATING_MAIN_COMPANY: locationsRatingsSlice.name,
};

export type TSliceReducer<
  SliceState,
  ActionCreator extends (...args: any) => any,
> = CaseReducer<SliceState, ReturnType<ActionCreator>>;

export type TActionType = 'pending' | 'fulfilled' | 'rejected';

export default store;
